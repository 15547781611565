import { RRuleSet, RRule } from 'rrule';
// import momenttz from 'moment-timezone';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import type { EventType, ExceptionSession, SessionPayloadType, SessionType } from '../../types/common';
import { ADD_EXCEPTION_SESSION_URL, ADD_SESSION_URL, GET_ALL_SESSION_URL, GET_SESSION_TYPE_URL, REQUEST_SESSION_URL, UPDATE_EXCEPTION_SESSION_URL, UPDATE_SESSION_URL } from '../api/config';
import API_MIDDLEWARE from '../api/middleware';
import { REFETCH_ALL_SESSION, RESET_CAL_FILTER_DATA, SET_ALL_SESSIONS_DATA, SET_CALENDAR_EVENTS, SET_CAL_FILTER_CLIENT_DATA, SET_CAL_FILTER_SESSIONTYPE, SET_SESSION_TYPES, TOGGLE_CAL_FILTER_STATE } from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { setErrorMessage, setLoadingState } from './appActions';
import { getDateAfterMonth, getEndDate, getEventTypeColor, getMinuteDifference, getPaymentEndDate, isDateInRange } from '../../commonFunctions/utils';
import { isEmpty } from 'lodash';
import { CANCELLED, CONFIRMED, CUSTOMER_APPROVAL_DENIED, FAILED, PAID_OUT, RESCHEDULED } from '../../constants/AppVarConstants';
import { API_VERSION } from './AppVarContants';
import { setDashboardClients } from './dashboardActions';

export const setSessionsAllData = (payload: any): any => {
  return { type: SET_ALL_SESSIONS_DATA, payload };
};

export const setCalendarEvents = (payload: EventType[]): any => {
  return { type: SET_CALENDAR_EVENTS, payload };
};

export const setSessionTypes = (payload: SessionType[]): any => {
  return { type: SET_SESSION_TYPES, payload };
};

export const refetchAllSession = (): any => {
  return { type: REFETCH_ALL_SESSION };
};

export const setCalFilterClientData = (payload: { clientId: string, clientName: string }): any => {
  return { type: SET_CAL_FILTER_CLIENT_DATA, payload };
};

export const setCalFilterSessionType = (payload: string): any => {
  return { type: SET_CAL_FILTER_SESSIONTYPE, payload };
};

export const resetCalendarFilters = (): any => {
  return { type: RESET_CAL_FILTER_DATA };
};

export const setCalFilterState = (payload: boolean): any => {
  return { type: TOGGLE_CAL_FILTER_STATE, payload };
};

export const fetchAllEvents = (
  payload: { clientId: string, typeId?: string | number, id?: string },
  setSortedData: any = (data: any) => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    dispatch(setLoadingState(true));
    API_MIDDLEWARE(
      `${GET_ALL_SESSION_URL}?id=${payload.id as string}`,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        if (!payload.clientId && payload.typeId === 1) {
          const clientSet = new Map();
          res?.results?.forEach((item: any) => {
            if (!clientSet.has(item?.clientId)) {
              clientSet.set(item?.clientId, item.clientName);
            }
          });
          const uniqueClients: any = [];
          clientSet.forEach((val: string, key: string) => {
            uniqueClients.push({ value: key, key: val });
          });
          const sortedClients = uniqueClients.sort((a: any, b: any) => a?.key?.localeCompare(b?.key));
          dispatch(setDashboardClients(sortedClients));
          setSortedData(res?.results);
        }
        dispatch(setSessionsAllData(res?.results));
        dispatch(setLoadingState(false));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(setLoadingState(false));
        dispatch(endAjaxCall());
      });
  };
};

export const filterEventsbyMonth = (sessionArr: any, currentMonth: string | Date): any => {
  return (dispatch: any, getState: any) => {
    try {
      dispatch(setLoadingState(true));
      let events: any = [];
      let sessions: any = [];
      const expSessions: any = [];
      sessionArr?.forEach((item: any) => {
        sessions = [];
        const confirmedPayments: any = [];
        const failedPayments: any = [];
        const commonData = {
          clientId: item.clientId,
          sessionId: item.sessionId,
          title: item?.clientName,
          client: item?.clientName,
          sessionTypeId: item?.type?.id,
          sessionType: item?.type?.type,
          repeatType: item?.recurrence ? 'Repeat' : 'No Repeat',
          recurrence: item?.recurrence,
          subscriptionId: item?.subscriptionId?.id ? item?.subscriptionId?.id : ''
        };
        if (item?.subscriptionId?.payments) {
          const paymentArr = item?.subscriptionId?.payments;
          paymentArr?.forEach((data: any) => {
            if (data?.status === CONFIRMED || data?.status === PAID_OUT) {
              confirmedPayments.push({
                startDate: data?.chargeDate,
                endDate: getPaymentEndDate(data?.chargeDate, item?.subscriptionId?.interval)
              });
            } else if (data?.status === FAILED || data?.status === CANCELLED || data?.status === CUSTOMER_APPROVAL_DENIED) {
              failedPayments.push({
                startDate: data?.chargeDate,
                endDate: getPaymentEndDate(data?.chargeDate, item?.subscriptionId?.interval)
              });
            }
          });
        }
        if (item?.recurrence && item?.recurrence.length > 50) {
          const ruleSet = new RRuleSet();
          let recurrenceRule = item.recurrence;
          if (!item?.recurrence.includes('COUNT') && !item?.recurrence.includes('UNTIL')) {
            const endDate = getDateAfterMonth(currentMonth);
            const newRecurrence = `${item?.recurrence as string};UNTIL=${endDate}`;
            recurrenceRule = newRecurrence;
          }
          const rule = RRule.fromString(recurrenceRule);
          // let lastDate: any = rule?.origOptions?.until;
          ruleSet.rrule(rule);
          let rescheduledSession: ExceptionSession[] = [];
          if (!isEmpty(item?.exceptions)) {
            rescheduledSession = item?.exceptions?.filter((item: ExceptionSession) => {
              if (item?.prevStart) {
                ruleSet.exdate(new Date(item.prevStart));
              } else if (item.type === CANCELLED) {
                ruleSet.exdate(new Date(item.start));
              }
              if (item.type === RESCHEDULED) {
                return item;
              }
              return null;
            });
          }
          sessions = ruleSet.all().map((date: Date) => {
            // const userState = getState().app.user;
            // const startDatetime = momenttz.tz(date, item.timezone);
            // Format the datetime for display in the app's timezone
            // const startDate = startDatetime.clone().tz(userState?.user?.zoneId).format();
            const startDate = date;
            const endDate = getEndDate(startDate, item?.durationInMinutes);
            const duration = item?.durationInMinutes;
            let payMStatus = item?.type?.id === 1 ? 'Pending' : 'Comp/Consultation';
            if (!isEmpty(confirmedPayments)) {
              confirmedPayments.forEach((data: any) => {
                if (isDateInRange(date, data?.startDate, data?.endDate)) {
                  payMStatus = 'Paid';
                }
              });
            }
            if (!isEmpty(failedPayments)) {
              failedPayments.forEach((data: any) => {
                if (isDateInRange(date, data?.startDate, data?.endDate)) {
                  payMStatus = 'Incomplete';
                }
              });
            }
            return {
              ...commonData,
              duration,
              exceptionId: '',
              color: getEventTypeColor(payMStatus),
              paymentStatus: item?.type?.id === 1 ? payMStatus : 'Comp/Consultation',
              start: startDate,
              end: endDate
            };
          });
          // if (!isEmpty(sessions)) {
          //   const lastEvent = [...sessions]?.pop();
          //   lastDate = lastEvent?.start;
          // }
          if (!isEmpty(rescheduledSession)) {
            rescheduledSession?.forEach((exp: ExceptionSession) => {
              let status = item?.type?.id === 1 ? 'Pending' : 'Comp/Consultation';
              if (!isEmpty(confirmedPayments)) {
                confirmedPayments.forEach((data: any) => {
                  if (isDateInRange(exp.start, data?.startDate, data?.endDate)) {
                    status = 'Paid';
                  }
                });
              }
              if (!isEmpty(failedPayments)) {
                failedPayments.forEach((data: any) => {
                  if (isDateInRange(exp.start, data?.startDate, data?.endDate)) {
                    status = 'Incomplete';
                  }
                });
              }
              const expSes = {
                ...commonData,
                recurrence: '',
                repeatType: 'No Repeat',
                start: new Date(exp.start),
                end: new Date(exp.end),
                duration: getMinuteDifference(exp.start, exp.end),
                exceptionId: exp?.exceptionId,
                color: getEventTypeColor(status),
                paymentStatus: item?.type?.id === 1 ? status : 'Comp/Consultation'
              };
              expSessions.push(expSes);
              // if (checkForValidDate(lastDate, exp.start)) {
              // }
            });
          }
        } else {
          let startDate = new Date(item?.start);
          let endDate = getEndDate(startDate, item?.durationInMinutes);
          let duration = item?.durationInMinutes;
          let exceptionId: any = '';
          let exceptionSessions = [];
          let addSession = true;
          let payMStatus = item?.type?.id === 1 ? 'Pending' : 'Comp/Consultation';
          if (!isEmpty(item?.exceptions)) {
            exceptionSessions = item.exceptions.sort((a: any, b: any) => {
              const dateA = new Date(a?.created).getTime();
              const dateB = new Date(b?.created).getTime();
              return dateB - dateA;
            });
            addSession = exceptionSessions[0]?.type === RESCHEDULED;
            startDate = new Date(exceptionSessions[0]?.start);
            endDate = new Date(exceptionSessions[0]?.end);
            duration = getMinuteDifference(startDate, endDate);
            exceptionId = exceptionSessions[0]?.exceptionId;
          }
          if (!isEmpty(confirmedPayments)) {
            confirmedPayments.forEach((data: any) => {
              if (isDateInRange(startDate, data?.startDate, data?.endDate)) {
                payMStatus = 'Paid';
              }
            });
          }
          if (!isEmpty(failedPayments)) {
            failedPayments.forEach((data: any) => {
              if (isDateInRange(startDate, data?.startDate, data?.endDate)) {
                payMStatus = 'Incomplete';
              }
            });
          }
          if (addSession) {
            sessions = [{
              ...commonData,
              duration,
              exceptionId,
              color: getEventTypeColor(payMStatus),
              paymentStatus: payMStatus,
              start: startDate,
              end: endDate
            }];
          }
        }
        events = [...events, ...sessions];
      });
      events = [...events, ...expSessions];
      dispatch(setCalendarEvents(events));
      dispatch(setLoadingState(false));
    } catch (error: any) {
      dispatch(setErrorMessage(error?.message ?? APP_CUSTOM_ERROR));
    }
  };
};

export const addNewSession = (
  payload: any,
  onSuccess = (res: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      ADD_SESSION_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess(res);
        dispatch(refetchAllSession());
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        onError();
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};

export const createExceptionSession = (
  payload: any,
  onSuccess = (res: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      ADD_EXCEPTION_SESSION_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess(res);
        dispatch(endAjaxCall());
        dispatch(refetchAllSession());
      })
      .catch((err) => {
        onError();
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};

export const updateExceptionSession = (
  payload: any,
  onSuccess = (res: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      UPDATE_EXCEPTION_SESSION_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess(res);
        dispatch(endAjaxCall());
        dispatch(refetchAllSession());
      })
      .catch((err) => {
        onError();
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};

export const getSessionTypes = (
  onSuccess = (res: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      GET_SESSION_TYPE_URL,
      'GET',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        onSuccess(res);
        let results = res?.results.map((item: any) => {
          return { key: item?.type, value: item.id };
        });
        results = results.filter((item: any) => item.key !== 'Free');
        dispatch(setSessionTypes(results));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        onError();
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};

export const updateSession = (
  payload: SessionPayloadType,
  onSuccess = (res: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${UPDATE_SESSION_URL}/${payload.sessionId as string}`,
      'PUT',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess(res);
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        onError();
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};

export const requestSession = (
  id: string,
  payload: any,
  onSuccess = (res: any) => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${REQUEST_SESSION_URL}/${id}/${API_VERSION}/create`,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess(res);
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};

export const requestCancelSession = (
  id: string,
  payload: any,
  onSuccess = (res: any) => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${REQUEST_SESSION_URL}/${id}/${API_VERSION}/cancel`,
      'PUT',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess(res);
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(endAjaxCall());
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };
};
